import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './assets/sass/custom.sass'
import '@mdi/font/css/materialdesignicons.css'
import IconCrypto from 'vue-cryptocurrency-icons'
import VueCrontab from 'vue-crontab'
import { checkData } from './helpers/checkData'
import VueCurrencyFilter from 'vue-currency-filter'

Vue.use(VueCurrencyFilter, {
  fractionCount: 0,
  thousandsSeparator: ',',
  symbolSpacing: false
})

Vue.use(VueCrontab)
Vue.use(IconCrypto)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  created() {
    checkData(this.$store)
  },
  render: h => h(App)
}).$mount('#app')
