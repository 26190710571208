import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#2196f3',
        secondary: '#607d8b',
        accent: '#00bcd4',
        error: '#f44336',
        warning: '#ffc107',
        info: '#009688',
        success: '#4caf50'
      }
    }
  }
})
