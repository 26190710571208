<template>
  <v-app>
    <!-- <v-app-bar app color="darken-3" dark>
      <h3 class="white--text text--darken-3 font-weight-medium">
        Cryptocurrency Rates
      </h3>
    </v-app-bar> -->

    <v-app-bar app color="darken-3" dark>
      <v-toolbar-title
        class="white--text text--darken-3 font-weight-medium mr-5"
      >
        Cryptocurrency Rates
      </v-toolbar-title>
      <v-autocomplete
        v-model="model"
        :items="items"
        :loading="isLoading"
        :search-input.sync="search"
        chips
        clearable
        hide-details
        hide-selected
        item-text="searchable"
        return-object
        label="Search for a coin..."
        solo
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              Search for
              <strong>Cryptocurrency</strong>
              eg. Bitcoin
            </v-list-item-title>
          </v-list-item>
        </template>
        <template v-slot:selection="{ attr, on, item, selected }">
          <v-chip
            v-bind="attr"
            :input-value="selected"
            color="blue-grey"
            class="white--text"
            v-on="on"
          >
            <span class="text-uppercase">{{ item.symbol }}&nbsp;</span>
          </v-chip>
        </template>
        <template v-slot:item="{ item }">
          <v-list-item-avatar
            color="grey"
            class="headline font-weight-light white--text"
          >
            {{ item.name.charAt(0) }}
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              v-text="item.symbol"
              class="text-uppercase"
            ></v-list-item-title>
            <v-list-item-subtitle v-text="item.name"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-app-bar>

    <v-main>
      <Main
        :model="model"
        :currencies="currencies"
        @updateCcy="updateCcy"
        @updateSearch="updateSearch"
      />
    </v-main>

    <v-footer absolute class="font-weight-medium footer">
      <v-col class="text-center" cols="12">
        <h5 class="dark--text text--darken-3 font-weight-medium">
          Note: Display rates are strictly valid for 15 minutes. Updated
          exchange rates apply thereafter.
        </h5>
        <h5 class="dark--text text--darken-3 font-weight-medium">
          Rates as of
          {{ moment(+startTime).format('MM/DD/YY hh:mmA') }}
        </h5>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import Main from './components/Main'
import { mapGetters } from 'vuex'
import moment from 'moment'

const apiURL = process.env.VUE_APP_API_URL

export default {
  name: 'App',
  computed: {
    ...mapGetters(['rates', 'startTime'])
  },
  data: () => ({
    moment,
    isLoading: false,
    items: [],
    search: null,
    model: null,
    selected: null,
    currencies: ['PHP', 'USD'],
    ccy: 'php'
  }),
  components: {
    Main
  },
  watch: {
    search() {
      let self = this
      // Items have already been loaded
      if (self.items.length > 0) return

      self.isLoading = true

      // Lazily load input items
      self.getRates()
    },
    model() {}
  },
  methods: {
    updateCcy(val) {
      this.ccy = val.toLowerCase()
      this.search = null
    },
    updateSearch(val) {
      this.ccy = val.toLowerCase()
      this.getRates()
    },
    getRates() {
      let self = this
      fetch(`${apiURL}/rates?type=all&ccy=${self.ccy}`)
        .then(res => res.clone().json())
        .then(res => {
          self.items = res
          for (const item of self.items) {
            item.searchable = `${item.name} ${item.symbol}`
          }
        })
        .finally(() => (self.isLoading = false))
    }
  }
}
</script>

<style>
.v-sheet.v-footer.footer {
  background-color: #fff !important;
  position: fixed !important;
  box-shadow: 0px -4px 20px 0 rgb(0 0 0 / 0.1) !important;
}
</style>
