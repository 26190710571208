import Vue from 'vue'
import Vuex from 'vuex'
import rateStore from './stores/RateStore'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ...rateStore.state
  },
  mutations: {
    ...rateStore.mutations
  },
  actions: {
    ...rateStore.actions
  },
  getters: {
    ...rateStore.getters
  }
})
