var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"app":"","color":"darken-3","dark":""}},[_c('v-toolbar-title',{staticClass:"white--text text--darken-3 font-weight-medium mr-5"},[_vm._v(" Cryptocurrency Rates ")]),_c('v-autocomplete',{attrs:{"items":_vm.items,"loading":_vm.isLoading,"search-input":_vm.search,"chips":"","clearable":"","hide-details":"","hide-selected":"","item-text":"searchable","return-object":"","label":"Search for a coin...","solo":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Search for "),_c('strong',[_vm._v("Cryptocurrency")]),_vm._v(" eg. Bitcoin ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"input-value":selected,"color":"blue-grey"}},'v-chip',attr,false),on),[_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(item.symbol)+" ")])])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"headline font-weight-light white--text",attrs:{"color":"grey"}},[_vm._v(" "+_vm._s(item.name.charAt(0))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-uppercase",domProps:{"textContent":_vm._s(item.symbol)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.name)}})],1)]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),_c('v-main',[_c('Main',{attrs:{"model":_vm.model,"currencies":_vm.currencies},on:{"updateCcy":_vm.updateCcy,"updateSearch":_vm.updateSearch}})],1),_c('v-footer',{staticClass:"font-weight-medium footer",attrs:{"absolute":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h5',{staticClass:"dark--text text--darken-3 font-weight-medium"},[_vm._v(" Note: Display rates are strictly valid for 15 minutes. Updated exchange rates apply thereafter. ")]),_c('h5',{staticClass:"dark--text text--darken-3 font-weight-medium"},[_vm._v(" Rates as of "+_vm._s(_vm.moment(+_vm.startTime).format('MM/DD/YY hh:mmA'))+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }