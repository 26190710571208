import moment from 'moment'
import NProgress from 'nprogress'

export function checkData($store, ccy) {
  const rates = localStorage.getItem('rates')
  const startTime = localStorage.getItem('startTime')
  if (ccy) ccy = ccy.toLowerCase()

  NProgress.start()

  /**
   *
   * Validate startTime
   * - Use cached data if less than 15 mins
   *
   */
  const created = moment(+startTime)
  const now = moment()
  const seconds = now.diff(created, 'seconds')

  if (seconds > 0) {
    getRates()
  }

  //
  else if (rates && startTime) {
    try {
      const ratesData = JSON.parse(rates)
      ratesData.startTime = startTime
      $store.commit('SET_RATES_DATA', { ratesData, startTime })
      NProgress.done()
    } catch (e) {
      getRates()
    }
  }

  function getRates() {
    $store
      .dispatch('getRates', { ccy })
      .then(res => {
        NProgress.done()
        return res
      })
      .catch(err => {
        this.error = err.toString()
      })
  }
}
