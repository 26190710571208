import axios from 'axios'
import moment from 'moment'

const apiURL = process.env.VUE_APP_API_URL

export default {
  state: {
    rates: null,
    startTime: null
  },
  mutations: {
    SET_RATES_DATA(state, data) {
      const rates = data.ratesData
      const startTime = data.startTime || moment.now()

      state.rates = rates
      state.startTime = startTime

      localStorage.setItem('rates', JSON.stringify(rates))
      localStorage.setItem('startTime', startTime)
    },
    CLEAR_RATES_DATA() {
      localStorage.removeItem('coins')
      localStorage.removeItem('startTime')
    }
  },
  actions: {
    getRates({ commit }, opts) {
      return axios
        .get(`${apiURL}/rates?ccy=${opts.ccy}`)
        .then(({ data }) => commit('SET_RATES_DATA', { ratesData: data }))
    }
  },
  getters: {
    startTime(state) {
      return state.startTime
    },
    rates(state) {
      return state.rates
    }
  }
}
