<template lang="pug">
  v-container.main-container.pa-0.ma-0.mt-5.rates-page.pa-lg-5.pa-md-5(fluid='')
    v-simple-table
      thead
        tr
          th.pa-5.font-weight-light.text-xs-h6.text-sm-h6.text-md-h5.text-lg-h5.text-xl-h4
            | Coin
          th.pa-5.font-weight-light.text-xs-h6.text-sm-h6.text-md-h5.text-lg-h5.text-xl-h4
            v-select(:items='currencies' filled='' v-model="ccy" label="BUY")
          th.pa-5.font-weight-light.text-xs-h6.text-sm-h6.text-md-h5.text-lg-h5.text-xl-h4
            v-select(:items='currencies' filled='' v-model="ccy" label="SELL")

      tbody(v-if="!search")
        tr(v-for='item in orderedRates' :key='item.id')
          td.pa-5.font-weight-light.text-xs-h4.text-sm-h5.text-md-h5.text-lg-h5.text-xl-h4
            IconCrypto.align-middle.mr-2(:coinname='item.symbol ? item.symbol : "generic"' format='svg')
            span.text-uppercase {{ item.symbol }}&nbsp;&nbsp;
            span.d-md-inline.d-lg-inline.d-xl-inline.d-none.blue-grey--text.text--lighten-3 {{ item.name }}
          td.cursor-pointer(@click="openDialog(item, 'buy')")
            span.pa-5.pr-0.pl-0.font-rubik.font-weight-light.text-xs-h4.text-sm-h5.text-md-h5.text-lg-h4.text-xl-h4.blue--text.text--darken-3 {{ item.buying.toString().split('.')[0] | currency }}
            span.pa-5.pl-0.font-rubik.font-weight-light.text-xs-h4.text-sm-h7.text-md-h7.text-lg-h6.text-xl-h6.blue--text.text--darken-3 .{{ getDecimals(item.buying) }}
          td.cursor-pointer(@click="openDialog(item, 'sell')")
            span.pa-5.pr-0.pl-0.font-rubik.font-weight-light.text-xs-h4.text-sm-h5.text-md-h5.text-lg-h4.text-xl-h4.blue--text.text--darken-3 {{ item.selling.toString().split('.')[0] | currency }}
            span.pa-5.pl-0.font-rubik.font-weight-light.text-xs-h4.text-sm-h7.text-md-h7.text-lg-h6.text-xl-h6.blue--text.text--darken-3 .{{ getDecimals(item.selling) }}
      tbody(v-if="search")
        tr(v-for='item in search' :key='item.id')
          td.pa-5.font-weight-light.text-xs-h4.text-sm-h5.text-md-h5.text-lg-h5.text-xl-h4
            IconCrypto.align-middle.mr-2(:coinname='item.symbol ? item.symbol : "generic"' format='svg')
            span.text-uppercase {{ item.symbol }}&nbsp;&nbsp;
            span.d-md-inline.d-lg-inline.d-xl-inline.d-none.blue-grey--text.text--lighten-3 {{ item.name }}
          td.cursor-pointer(@click="openDialog(item, 'buy')")
            span.pa-5.pr-0.pl-0.font-rubik.font-weight-light.text-xs-h4.text-sm-h5.text-md-h5.text-lg-h4.text-xl-h4.blue--text.text--darken-3 {{ item.buying.toString().split('.')[0] | currency }}
            span.pa-5.pl-0.font-rubik.font-weight-light.text-xs-h4.text-sm-h7.text-md-h7.text-lg-h6.text-xl-h6.blue--text.text--darken-3 .{{ getDecimals(item.buying) }}
          td.cursor-pointer(@click="openDialog(item, 'sell')")
            span.pa-5.pr-0.pl-0.font-rubik.font-weight-light.text-xs-h4.text-sm-h5.text-md-h5.text-lg-h4.text-xl-h4.blue--text.text--darken-3 {{ item.selling.toString().split('.')[0] | currency }}
            span.pa-5.pl-0.font-rubik.font-weight-light.text-xs-h4.text-sm-h7.text-md-h7.text-lg-h6.text-xl-h6.blue--text.text--darken-3 .{{ getDecimals(item.selling) }}

    v-dialog(v-model='dialog.open' width='600')
      v-card(:color='dialog.data.color')
        <v-card-title class="headline">
          span.text-uppercase.mr-2.white--text {{ dialog.data.symbol }}
          span.white--text.text--secondary {{ dialog.data.name }}
        </v-card-title>
        v-divider

        v-card-text.pa-5.mb-5.mt-5
          .text-h7.text-lg-h6.text-xl-h6.white--text.text-uppercase.font-rubik {{ dialog.type }}
          span.text-h4.text-lg-h2.text-xl-h2.pa-5.pr-0.pl-0.font-rubik.font-weight-light.white--text.text--darken-3.text-uppercase.mr-1 {{ dialog.data.base }}
          span.text-h4.text-lg-h2.text-xl-h2.pa-5.pr-0.pl-0.font-rubik.font-weight-light.white--text.text--darken-3 {{ dialog.data.whole | currency }}
          span.text-h6.text-lg-h6.text-xl-h6.pa-5.pl-0.font-rubik.font-weight-light.white--text.text--darken-3 .{{ dialog.data.dec }}
          h3.font-weight-medium.white--text.text--secondary Rates as of {{ moment(+startTime).format('MM/DD/YY hh:mmA') }}
          h4.font-weight-medium.white--text.text--secondary.mt-1 Note: Display rates are strictly valid for 15 minutes. Updated exchange rates apply thereafter.
          IconCrypto.align-middle.mr-2.bg-img(:coinname='dialog.data.symbol' format='svg' color="white")

        v-divider
        v-card-actions
          v-spacer
          v-btn(color='white' text='' @click='dialog.open = false')
            | Close
</template>

<script>
import { mapGetters } from 'vuex'
import { checkData } from '../helpers/checkData'
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'Main',
  props: ['model', 'currencies'],

  computed: {
    ...mapGetters(['rates', 'startTime']),
    orderedRates: function() {
      return _.orderBy(this.rates, 'id')
    }
  },

  data: () => ({
    dialog: {
      open: false,
      type: 'buy',
      data: {}
    },
    counter: 0,
    moment,
    search: null,
    ccy: 'PHP',
    interval: null
  }),

  methods: {
    openDialog(item, type) {
      this.dialog.data = item
      this.dialog.type = type

      const price = item[`${type}ing`]
      const whole = price.toString().split('.')[0]
      const dec = this.getDecimals(price)
      this.dialog.data.whole = whole
      this.dialog.data.dec = dec

      this.dialog.open = true
    },
    checkData() {
      checkData(this.$store, this.ccy.toLowerCase())
    },
    getDecimals(value) {
      if (value) {
        let places = 2

        if (value <= 100) places = 3
        if (value <= 10) places = 4
        if (value <= 1) places = 5
        if (value <= 0.1) places = 6
        if (value <= 0.01) places = 7
        if (value <= 0.001) places = 8
        if (value <= 0.0001) places = 9
        if (value <= 0.00001) places = 10

        const valueString = value.toString().split('.')[1]
        const decimalValue = valueString.substr(0, places)

        return decimalValue
      }
    }
  },

  watch: {
    model(val) {
      if (val) {
        this.search = []
        this.search.push(val)
      } else {
        this.search = null
      }
    },
    ccy(val) {
      this.$emit('updateCcy', val)
      if (this.search && this.search.length) {
        this.$emit('updateSearch', val.toLowerCase())
      } else {
        checkData(this.$store, val.toLowerCase())
      }
    }
  },

  mounted() {
    let self = this
    self.interval = setInterval(() => {
      checkData(self.$store, self.ccy.toLowerCase())
    }, 5000)
  }
}
</script>

<style scoped>
td img {
  width: 35px;
  margin-top: -5px;
}

.v-data-table {
  border: thin solid rgba(0, 0, 0, 0.12);
}

.v-application .main-container {
  margin-bottom: 100px !important;
}
</style>
